import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

import { useI18n } from 'vue-i18n'
import Button from '@/components_new/Button.vue'
import Typography from '@/components_new/Typography.vue'
import fennekImg from './images/fennek.webp'
import { useCategoriesStore } from '@/store/categories-store'
import { storeToRefs } from 'pinia'

type PromoCategoriesProps = {
    hasHeader?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PromoCategories',
  props: {
    hasHeader: { type: Boolean }
  },
  setup(__props: any) {

const categoriesStore = useCategoriesStore()

const { promoCategoriesList } = storeToRefs(categoriesStore)

const props = __props

const { t } = useI18n()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.promo)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.texts)
    }, [
      _createVNode(Typography, {
        is: props.hasHeader ? 'h1' : 'div',
        type: "header",
        size: "xs",
        responsive: false
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)('title')), 1)
        ]),
        _: 1
      }, 8, ["is"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.text)
      }, [
        _createVNode(Typography, {
          type: "paragraph",
          size: "xs"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('text')), 1)
          ]),
          _: 1
        })
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.categories)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(promoCategoriesList), (category) => {
        return (_openBlock(), _createBlock(Button, {
          key: category.id,
          link: `/category/${category.id}`,
          color: "carbon-400",
          iconLeft: category.id
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(category.title), 1)
          ]),
          _: 2
        }, 1032, ["link", "iconLeft"]))
      }), 128))
    ], 2),
    _createElementVNode("img", {
      src: _unref(fennekImg),
      alt: _unref(t)('img_alt'),
      class: _normalizeClass(_ctx.$style.fennek),
      loading: "lazy"
    }, null, 10, _hoisted_1)
  ], 2))
}
}

})