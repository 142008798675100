<template>
    <CardCarousel
        :items="items"
        :dummy="isDummy"
        :title="props.title"
        :link="props.link"
        :lastItemLink="props.lastItemLink"
        :rows="props.rows ?? (props.big ? 1 : 2)"
    >
        <template #default="{ index, item: game, className, bigCardClassName }">
            <template v-if="props.promo && index === promoPosition">
                <AddGamePromoCard
                    v-if="props.promo.type === 'add_game'"
                    :appearance="itemIsBig(index) ? 'big' : 'medium'"
                    responsive
                    :class="{ [className]: true, [bigCardClassName]: itemIsBig(index) }"
                />
            </template>
            <GameCard
                :game="game"
                :appearance="itemIsBig(index) ? 'big' : 'medium'"
                :targetLink="props.targetLinkGame"
                responsive
                :class="{ [className]: true, [bigCardClassName]: itemIsBig(index) }"
                :lazy="props.eagerThumbs ? index >= props.eagerThumbs : true"
            />
        </template>
    </CardCarousel>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { type ShortGameType } from '@/types'
import GameCard from '@/components_new/GameCard.vue'
import CardCarousel from '@/components_new/CardCarousel.vue'
import AddGamePromoCard from '@/components_new/AddGamePromoCard.vue'

interface GameCarouselProps {
    title?: string
    link?: string
    games?: ShortGameType[]
    big?: boolean
    firstItemBig?: boolean
    lastItemLink?: string
    promo?: { type: 'add_game'; position?: number }
    targetLinkGame?: '_blank'
    eagerThumbs?: number
    rows?: number
}

const props = defineProps<GameCarouselProps>()

const items = computed(() => props.games || Array<undefined>(20).fill(undefined))
const isDummy = computed(() => !props.games)
const promoPosition = computed(() => props.promo?.position ?? 0)

function itemIsBig(index: number): boolean {
    if (props.big || (props.firstItemBig && index === 0)) {
        return true
    }
    return false
}
</script>
