import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

import { onServerPrefetch, onMounted, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useHead } from '@unhead/vue'
import { useCategoriesStore } from '@/store/categories-store'
import { useHomepageStore, type MainCategoryListZipType } from './homepage-store'
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import TheEnd from '@/components_new/TheEnd/TheEnd.vue'
import GameCardCarousel from '@/components_new/GameCardCarousel.vue'
import PromoCategories from '@/components_new/PromoCategories/PromoCategories.vue'
import { type MainCategoriesType } from '@/types'
import PromoSignInHorizontal from '@/components_new/PromoItems/components/PromoSignInHorizontal.vue'
import Tasks from '@/components_new/TaskItems/Tasks.vue'
import PromoInviteHorizontal from '@/components_new/PromoItems/components/PromoInviteHorizontal.vue'
import PromoSignInVertical from '@/components_new/PromoItems/components/PromoSignInVertical.vue'
import PromoInviteVertical from '@/components_new/PromoItems/components/PromoInviteVertical.vue'
import AdBlock from '@/components_new/AdBlock.vue'
import PromoAddGameHorizontal from '@/components_new/PromoItems/components/PromoAddGameHorizontal.vue'
import PromoAddGameHorizontalShort from '@/components_new/PromoItems/components/PromoAddGameHorizontalShort.vue'
import { unzipShortGame } from '@/utils/helpers'
import { useConfig } from '@/composables/config'


export default /*@__PURE__*/_defineComponent({
  __name: 'Homepage',
  setup(__props) {

const categoriesStore = useCategoriesStore()
const homepageStore = useHomepageStore()

const { categoriesWithGames } = storeToRefs(homepageStore)

const { VUE_APP_HOST_URL } = useConfig()
useHead({
    link: [
        {
            rel: 'canonical',
            href: VUE_APP_HOST_URL,
        },
    ],
})

function prepareCategories(categories: MainCategoryListZipType[]) {
    return categories.map((category, index) => {
        const isBig = index % 2 === 0 && index > 0
        const firstItemBig = index === 0

        let games = category.games?.map(unzipShortGame)
        if (!isBig && games) {
            if ((firstItemBig && games.length % 2 === 0) || (!firstItemBig && games.length % 2 === 1)) {
                games = games.slice(0, -1)
            }
        }
        return {
            id: category.id,
            title: category.title,
            href: category.href,
            games,
            isBig,
            firstItemBig,
        }
    })
}

const placeholderCategories = computed(() => {
    return categoriesStore.contentCategoriesList.length > 0
        ? categoriesStore.contentCategoriesList.map((category) => ({
              // map contentCategoriesList to ensure that no "games" field is present so caroursel will be dummy
              id: category.id,
              title: category.title,
              href: category.href,
          }))
        : Array(5)
              .fill(null)
              .map((_, index) => ({
                  id: `placeholder-${index}` as MainCategoriesType,
                  title: '\u00A0', // non-breaking space to preserve title space
                  href: '',
              }))
})

const preparedCategories = computed(() =>
    prepareCategories(categoriesWithGames.value.length > 0 ? categoriesWithGames.value : placeholderCategories.value),
)

// show ad only when data is loaded, otherwise it will soon remounted and re-requested again.
const showAd = computed(() => categoriesWithGames.value.length > 0)

onServerPrefetch(async () => {
    await Promise.all([categoriesStore.setMainData(), homepageStore.loadCategoriesWithGames()])
})

onMounted(async () => {
    await Promise.all([categoriesStore.setMainData(), homepageStore.loadCategoriesWithGames()])
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Layout, null, {
    default: _withCtx(() => [
      _createElementVNode("main", {
        class: _normalizeClass(_ctx.$style.page)
      }, [
        _createVNode(PromoSignInHorizontal, {
          class: _normalizeClass(_ctx.$style.tablet)
        }, null, 8, ["class"]),
        _createVNode(PromoAddGameHorizontal, {
          class: _normalizeClass(_ctx.$style.desktop)
        }, null, 8, ["class"]),
        _createVNode(Tasks, {
          class: _normalizeClass(_ctx.$style.tablet)
        }, null, 8, ["class"]),
        _createVNode(Tasks, {
          class: _normalizeClass(_ctx.$style.mobile)
        }, {
          default: _withCtx(() => [
            _createVNode(PromoInviteVertical)
          ]),
          _: 1
        }, 8, ["class"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(preparedCategories.value, (category, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: category.id
          }, [
            _createVNode(GameCardCarousel, {
              title: category.title,
              link: category.id !== 'random' ? category.href : undefined,
              games: category.games,
              big: category.isBig,
              firstItemBig: category.firstItemBig,
              lastItemLink: category.href,
              targetLinkGame: "_blank",
              promo: category.id === 'new' ? { type: 'add_game' } : undefined,
              eagerThumbs: index < 3 ? 3 : 0
            }, null, 8, ["title", "link", "games", "big", "firstItemBig", "lastItemLink", "promo", "eagerThumbs"]),
            (category.id === 'new')
              ? (_openBlock(), _createBlock(PromoSignInVertical, {
                  key: 0,
                  class: _normalizeClass(_ctx.$style.mobile)
                }, null, 8, ["class"]))
              : _createCommentVNode("", true),
            (index === 0)
              ? (_openBlock(), _createBlock(PromoInviteHorizontal, {
                  key: 1,
                  class: _normalizeClass(_ctx.$style.tablet)
                }, null, 8, ["class"]))
              : _createCommentVNode("", true),
            (showAd.value && index === 1)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createVNode(AdBlock, {
                    type: "leaderboard",
                    class: _normalizeClass(_ctx.$style.tabletPlus),
                    emptyClass: _ctx.$style.emptyRow
                  }, null, 8, ["class", "emptyClass"]),
                  _createVNode(AdBlock, {
                    type: "catalog_mobile",
                    class: _normalizeClass(_ctx.$style.mobilePortrait),
                    emptyClass: _ctx.$style.emptyRow
                  }, null, 8, ["class", "emptyClass"])
                ], 64))
              : _createCommentVNode("", true),
            (index === 2)
              ? (_openBlock(), _createBlock(PromoAddGameHorizontalShort, {
                  key: 3,
                  class: _normalizeClass(_ctx.$style.mobile)
                }, null, 8, ["class"]))
              : _createCommentVNode("", true),
            (index === 4)
              ? (_openBlock(), _createBlock(PromoAddGameHorizontal, {
                  key: 4,
                  class: _normalizeClass(_ctx.$style.tablet)
                }, null, 8, ["class"]))
              : _createCommentVNode("", true),
            (showAd.value && index === 4)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                  _createVNode(AdBlock, {
                    type: "catalog",
                    class: _normalizeClass(_ctx.$style.tabletPlus),
                    emptyClass: _ctx.$style.emptyRow
                  }, null, 8, ["class", "emptyClass"]),
                  _createVNode(AdBlock, {
                    type: "catalog_mobile_rest",
                    class: _normalizeClass(_ctx.$style.mobilePortrait),
                    emptyClass: _ctx.$style.emptyRow
                  }, null, 8, ["class", "emptyClass"])
                ], 64))
              : _createCommentVNode("", true),
            (index === 7)
              ? (_openBlock(), _createBlock(PromoCategories, {
                  key: 6,
                  hasHeader: ""
                }))
              : _createCommentVNode("", true),
            (showAd.value && index > 7 && (index - 7) % 3 === 0)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
                  _createVNode(AdBlock, {
                    type: "leaderboard_rest",
                    class: _normalizeClass(_ctx.$style.tabletPlus),
                    emptyClass: _ctx.$style.emptyRow,
                    intersectionRootMargin: "500px 0px"
                  }, null, 8, ["class", "emptyClass"]),
                  _createVNode(AdBlock, {
                    type: "catalog_mobile_rest",
                    class: _normalizeClass(_ctx.$style.mobilePortrait),
                    emptyClass: _ctx.$style.emptyRow,
                    intersectionRootMargin: "500px 0px"
                  }, null, 8, ["class", "emptyClass"])
                ], 64))
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        _createVNode(TheEnd, { targetUrl: "_blank" })
      ], 2)
    ]),
    _: 1
  }))
}
}

})