import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

import { computed } from 'vue'
import { type ShortGameType } from '@/types'
import GameCard from '@/components_new/GameCard.vue'
import CardCarousel from '@/components_new/CardCarousel.vue'
import AddGamePromoCard from '@/components_new/AddGamePromoCard.vue'

interface GameCarouselProps {
    title?: string
    link?: string
    games?: ShortGameType[]
    big?: boolean
    firstItemBig?: boolean
    lastItemLink?: string
    promo?: { type: 'add_game'; position?: number }
    targetLinkGame?: '_blank'
    eagerThumbs?: number
    rows?: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GameCardCarousel',
  props: {
    title: {},
    link: {},
    games: {},
    big: { type: Boolean },
    firstItemBig: { type: Boolean },
    lastItemLink: {},
    promo: {},
    targetLinkGame: {},
    eagerThumbs: {},
    rows: {}
  },
  setup(__props: any) {

const props = __props

const items = computed(() => props.games || Array<undefined>(20).fill(undefined))
const isDummy = computed(() => !props.games)
const promoPosition = computed(() => props.promo?.position ?? 0)

function itemIsBig(index: number): boolean {
    if (props.big || (props.firstItemBig && index === 0)) {
        return true
    }
    return false
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CardCarousel, {
    items: items.value,
    dummy: isDummy.value,
    title: props.title,
    link: props.link,
    lastItemLink: props.lastItemLink,
    rows: props.rows ?? (props.big ? 1 : 2)
  }, {
    default: _withCtx(({ index, item: game, className, bigCardClassName }) => [
      (props.promo && index === promoPosition.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (props.promo.type === 'add_game')
              ? (_openBlock(), _createBlock(AddGamePromoCard, {
                  key: 0,
                  appearance: itemIsBig(index) ? 'big' : 'medium',
                  responsive: "",
                  class: _normalizeClass({ [className]: true, [bigCardClassName]: itemIsBig(index) })
                }, null, 8, ["appearance", "class"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(GameCard, {
        game: game,
        appearance: itemIsBig(index) ? 'big' : 'medium',
        targetLink: props.targetLinkGame,
        responsive: "",
        class: _normalizeClass({ [className]: true, [bigCardClassName]: itemIsBig(index) }),
        lazy: props.eagerThumbs ? index >= props.eagerThumbs : true
      }, null, 8, ["game", "appearance", "targetLink", "class", "lazy"])
    ]),
    _: 1
  }, 8, ["items", "dummy", "title", "link", "lastItemLink", "rows"]))
}
}

})